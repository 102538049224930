#admin-nav-open{
    position: fixed;
    background-color: #395076;
    height: 100vh;
    box-sizing: border-box;
    padding: 1rem;
    width: 250px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
}

#admin-nav-closed{
    background-color: #395076;
    height: 100vh;
    box-sizing: border-box;
    padding: 1rem;
    width: 60px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    position: fixed;

}

#nav-logo{
    width: 70%;
    margin: 2rem auto 3rem auto;
}
#logo-closed{
    width: 35px;
    height: 35px;
    margin-left: -3px;
    margin-bottom: 20px;
}

.nav-item{
    display: flex;
    flex-direction: row;
    align-items: center;

}
.nav-item:hover{
    cursor: pointer;
}

.nav-title{
    font-family: -apple-system-headline, BlinkMacSystemFont, sans-serif;
    margin-left: 2rem;
    color: white;
    font-weight: bold;
}

.nav-title-closed{
    visibility: hidden;
}

#log-out-btn{
    margin: 1rem;
    left: 60px;
    background-color: #395076;
    color: white;
    box-sizing: border-box;
    width: 130px;
    height: 30px;
    border-radius: 25px;
    border: 2px solid white;
    font-weight: bold;
    transition: 0.3s;
}

#log-out-btn:hover{
    cursor: pointer;
    background-color: #56719d;
}

#menu-btn{
    background-color: #fff;
    color: #395076;
    box-sizing: border-box;
    width: 130px;
    height: 30px;
    border-radius: 15px;
    border: unset;
    font-weight: bold;
    transition: 0.3s;
  
    left: 60px;
}

#menu-btn:hover{
    cursor: pointer;
    background-color: rgb(230, 230, 230);
}

#main-open{
    width: calc(100vw - 250px);
    margin-left: 250px;
    padding: 1rem;
    transition: 0.5s;
    box-sizing: border-box;

}

#main-closed{
    width: calc(100vw - 60px);
    margin-left: 60px;
    box-sizing: border-box;
    padding: 1rem;
    transition: 0.5s;
}

a{
    text-decoration: none;
}

#btn-container{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mobile-img{
    display: block;
    margin: 1rem auto;
    max-width: 90%;
    height: auto;
}